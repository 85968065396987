$color-brand: #ff6d24;
$color-danger: #ff4238;
$color-error: #eb5768;
$color-success: #54b58b;
$color-heading: #010101;
$color-main: #1f2328;
$color-secondary: #4b5563;
$color-tertiary: #656d76;
$color-quaternary: #9ca3af;
$color-base-1: #1f2328;
$color-base-2: #33383ff0;
$color-base-3: #6f767e;
$color-primary-50: #f4f6ff;
$color-primary-100: #e2e8ff;
$color-primary-200: #beccff;
$color-primary-300: #9ab1ff;
$color-primary-400: #7795ff;
$color-primary-500: #5379ff;
$color-primary-600: #2a59ff;
$color-primary-700: #0139ff;
$color-primary-800: #0030d8;
$color-primary-900: #0027af;
$color-primary: #5379ff;
$color-gray-50: #fafafa;
$color-gray-100: #f5f5f5;
$color-gray-200: #e5e5e5;
$color-gray-300: #d4d4d4;
$color-gray-400: #a3a3a3;
$color-gray-500: #737373;
$color-gray-600: #525252;
$color-gray-700: #404040;
$color-gray-800: #262626;
$color-gray-900: #171717;
$color-gray-950: #0a0a0a;
$color-light-1: #f4f4f4;
$color-light-2: rgba(208, 215, 222, 0.32);
$font-size-base: 15px;
$border-radius: 5px;
$border-radius-xs: 2px;
$border-radius-sm: 4px;
$border-radius-md: 6px;
$border-radius-lg: 8px;
$border-radius-xl: 12px;
