.ant-form-item {
  &.efh-form-item {
    .ant-form-item-label {
      font-size: 14px;
      line-height: 21px;
      color: #444444;
      font-weight: 600;
    }
  }
}
