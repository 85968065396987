.ant-modal.ant-modal-confirm {
  .ant-modal-confirm-btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.zo-modal {
  .ant-modal-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 16px;
    padding-left: 20px;
  }

  .ant-modal-content {
    padding: 0 !important;
    overflow: hidden;
  }

  .ant-modal-footer {
    display: none !important;
  }

  .ant-modal-close {
    top: 12px;
    right: 12px;
  }
}

.zo-modal-2 {
  .ant-modal-header {
    height: 48px;
    background: #e5e7eb;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
  }

  .ant-modal-content {
    padding: 0 !important;
    overflow: hidden;
  }

  .ant-modal-close {
    top: 0;
    right: 0;
    height: 48px;
    width: 48px;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #d1d5db;

    &:hover {
      background: #d1d5db;
    }
  }
}
