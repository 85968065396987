@tailwind base;
@tailwind components;
@tailwind utilities;

@import './_tailwind-variables';

@import './components/input';

@import './antd/index';

@import './scrollbar';
@import './react-contexify/main';

html {
  font-family: 'Inter Variable', sans-serif;
  overscroll-behavior: none;
}

body {
  margin: 0;
  font-size: 15px;
  line-height: 24px;
  font-family: 'Inter Variable', sans-serif;
  overscroll-behavior: none;
}

* {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #dde2e8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #111827;
}

main {
  min-height: calc(100vh - 60px);
}

.chain-input-container {
  font-family: 'Inter Variable', sans-serif;
  border: 1px solid #dfdfdf;
  background: white;
  padding: 7px 10px;
  border-radius: 5px;

  &.ProseMirror-focused {
    outline: none !important;
    border-color: #5379fe;
    transition: all ease-out 150ms;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 22px;
    font-size: 14px;
  }

  .variable {
    font-size: 12px;
    line-height: 1;
    color: white;
    border-radius: 3px;
    vertical-align: middle;
    padding: 2px 4px;
    margin-left: 1px;
    margin-right: 1px;
    cursor: pointer;
    white-space: nowrap !important;
  }

  .function {
    font-size: 12px;
    line-height: 1;
    background-color: #dedede;
    border-radius: 3px;
    vertical-align: middle;
    padding: 2px 4px;
    margin-left: 1px;
    margin-right: 1px;
    cursor: pointer;
    white-space: nowrap !important;
  }
}

.no-transition {
  transition: none !important;
  animation: none !important;
}

/*
 *****************************************
 * 
 *
 */

.react-flow__panel.react-flow__attribution.bottom.right {
  display: none !important;
}

/*
 *****************************************
 * 
 *
 */

.no-border-last {
  .ant-table-tbody {
    .ant-table-row {
      &:last-child {
        .ant-table-cell {
          border-bottom: none;
        }
      }
    }
  }
}