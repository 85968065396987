:root {
  --contexify-zIndex: 666;
  --contexify-menu-minWidth: 180px;
  --contexify-menu-padding: 6px;
  --contexify-menu-radius: 8px;
  --contexify-menu-bgColor: #fff;
  --contexify-menu-shadow: rgba(31, 35, 40, 0.12) 0px 1px 3px, rgba(66, 74, 83, 0.12) 0px 8px 24px;
  --contexify-menu-negatePadding: var(--contexify-menu-padding);

  --contexify-separator-color: rgba(0, 0, 0, 0.2);
  --contexify-separator-margin: 5px;
  --contexify-itemContent-padding: 4px 6px;
  --contexify-activeItem-radius: 6px;
  --contexify-item-color: #333;
  --contexify-activeItem-color: #1f2328;
  --contexify-activeItem-bgColor: rgba(208, 215, 222, 0.32);
  --contexify-rightSlot-color: #6f6e77;
  --contexify-activeRightSlot-color: #fff;
  --contexify-arrow-color: #6f6e77;
  --contexify-activeArrow-color: #fff;
}

@mixin focus-swag {
  color: var(--contexify-activeItem-color);
  background-color: var(--contexify-activeItem-bgColor);
  border-radius: var(--contexify-activeItem-radius);
}

@function negateMenuPadding() {
  @return calc(-1 * var(--contexify-menu-negatePadding));
}

// triggered when user select item from the context menu
@keyframes contexify_feedback {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.contexify {
  position: fixed;
  opacity: 0;
  user-select: none;
  background-color: var(--contexify-menu-bgColor);
  box-sizing: border-box;
  box-shadow: var(--contexify-menu-shadow);
  border-radius: var(--contexify-menu-radius);
  padding: var(--contexify-menu-padding);
  min-width: var(--contexify-menu-minWidth);
  z-index: var(--contexify-zIndex);
  font-size: 14px;

  &_submenu-isOpen,
  &_submenu-isOpen > &_itemContent {
    @include focus-swag;
  }

  // target submenu arrow
  &_submenu-isOpen > &_itemContent &_rightSlot {
    color: var(--contexify-activeArrow-color);
  }

  &_submenu-isOpen > &_submenu {
    pointer-events: initial;
    opacity: 1;
  }

  & &_submenu {
    position: absolute;
    pointer-events: none;
    // transition: opacity 0.265s;
    transition: opacity 0.1s;

    /* Initial submenu position */
    top: negateMenuPadding();
    left: 100%;

    &-bottom {
      bottom: negateMenuPadding();
      top: unset;
    }

    &-right {
      right: 100%;
      left: unset;
    }
  }

  &_rightSlot {
    margin-left: auto;
    display: flex;
    color: var(--contexify-rightSlot-color);
  }

  &_separator {
    height: 1px;
    cursor: default;
    margin: var(--contexify-separator-margin);
    background-color: var(--contexify-separator-color);
  }

  &_willLeave-disabled {
    pointer-events: none;
  }

  &_item {
    cursor: pointer;
    position: relative;

    &:focus {
      outline: 0;
    }

    &:not(&-disabled):hover > .contexify_itemContent .contexify_rightSlot,
    &:focus .contexify_rightSlot {
      color: var(--contexify-activeRightSlot-color);
    }

    &:not(&-disabled)[aria-haspopup] > .contexify_itemContent .contexify_rightSlot {
      color: var(--contexify-arrow-color);
    }

    // triggered by keyboard navigation
    &[aria-haspopup]:focus > .contexify_itemContent .contexify_rightSlot,
    &:not(&-disabled)[aria-haspopup].contexify_submenu-isOpen > .contexify_itemContent .contexify_rightSlot,
    &:not(&-disabled)[aria-haspopup]:hover > .contexify_itemContent .contexify_rightSlot {
      color: var(--contexify-activeArrow-color);
    }

    &:not(&-disabled):hover > &Content,
    &:not(&-disabled):focus > &Content {
      @include focus-swag;
    }

    &:not(&-disabled):hover > .contexify_submenu {
      pointer-events: initial;
      opacity: 1;
    }

    &-disabled {
      cursor: default;
      opacity: 0.5;
    }
    &Content {
      padding: var(--contexify-itemContent-padding);
      display: flex;
      align-items: center;
      white-space: nowrap;
      color: var(--contexify-item-color);
      position: relative;
    }

    &-feedback {
      animation: contexify_feedback 0.12s both;
    }
  }
}
