.zo-base-flat-input {
  position: relative;

  input {
    outline: none;
    padding: 8px 0 8px 0;
    font-size: 16px;
    background-color: transparent;
    width: 100%;
  }

  &:focus-within {
    background-color: #ffffff;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -8px;
      width: 8px;
      background-color: #ffffff;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: -8px;
      width: 8px;
      background-color: #ffffff;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}

.zo-base-flat-input-no-style {
  position: relative;

  input {
    outline: none;
    padding: 8px 0 8px 0;
    font-size: 16px;
    background-color: transparent;
    width: 100%;
  }
}

.zo-base-field {
  .zo-base-field-label {
    color: #444444;
    font-size: 14px;
    font-weight: 600;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
    transition: all 0.15s ease-in-out;

    .ant-form-item-explain {
      padding-top: 16px;

      .ant-form-item-explain-error {
        position: relative;
        padding-left: 20px;
        font-size: 12px;
        line-height: 1;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: -1px;
          width: 12px; // Adjust according to icon size
          height: 12px; // Adjust according to icon size
          background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="red" viewBox="0 0 16 16"><path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z"/><path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/></svg>')
            no-repeat center center;
          background-size: contain;
        }
      }
    }
  }

  .field-select-multiple {
    .ant-form-item-control-input-content {
      max-height: 195px;
      overflow-y: auto;
      border: 1px solid #e5e5e5;
      border-radius: 6px;

      .ant-checkbox-group {
        .ant-checkbox-group-item {
          background-color: #ffffff;
          width: 100% !important;
          padding: 8px 12px;

          &:hover {
            background-color: rgba(0, 0, 0, 0.02);
          }
        }

        .ant-checkbox-group-item:not(:last-child) {
          border-bottom: 1px solid rgba(229, 229, 229, 0.5);
        }
      }
    }
  }
}
